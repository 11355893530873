























import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import { tipos_fichaje } from "@/shared/dtos/tipos_fichaje";
import DxColorBox from "devextreme-vue/color-box";
@Component({
  components: { DxColorBox },
})
export default class tipos_fichajeFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: tipos_fichaje = new tipos_fichaje();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await tipos_fichajeModule.gettipos_fichaje(this.getparamId().Parametro);
      this.item_pantalla = tipos_fichajeModule.tipos_fichaje;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 6, 4);

    this.AddFormItemDataDefaultBoolean(
      this.item_pantalla.festivos_convenio,
      "Festivo convenio",
      "festivos_convenio",
      1,
      "Festivo convenio",
      "Festivo convenio"
    ).Dimesiones(12, 4, 2);

    this.AddFormItemDataDefaultBoolean(
      this.item_pantalla.festivos_empleado,
      "Festivo empleado",
      "festivos_empleado",
      1,
      "Festivo empleado",
      "Festivo empleado"
    ).Dimesiones(12, 4, 2);

    this.AddFormItemDataDefaultSlot(
      this.item_pantalla.color,
      "Color",
      "color",
      60
    )
      .isRequired()
      .Dimesiones(12, 4, 2);
    /*
    public festivos_convenio !: boolean;
    public festivos_empleado !: boolean;
    public color !: string; 
*/
  }

  public Insertar(object: tipos_fichaje) {
    tipos_fichajeModule.guardartipos_fichaje(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: tipos_fichaje) {
    tipos_fichajeModule.modificartipos_fichaje(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
